"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "usePartner", {
    enumerable: true,
    get: function() {
        return usePartner;
    }
});
const _react = require("react");
const _UserContext = require("../../contexts/UserContext");
function usePartner(name) {
    const { user } = (0, _react.useContext)(_UserContext.UserContext);
    const partner = (0, _react.useMemo)(()=>{
        return user === null || user === void 0 ? void 0 : user.partners.find((partner)=>partner.name === name);
    }, [
        user,
        name
    ]);
    return partner;
}
