"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useWarningMessageForGuideUrlTag", {
    enumerable: true,
    get: function() {
        return useWarningMessageForGuideUrlTag;
    }
});
const _react = require("react");
const _usePartner = require("../usePartner");
function useWarningMessageForGuideUrlTag(value) {
    const touchstay = (0, _usePartner.usePartner)("touchstay");
    const warningMessage = (0, _react.useMemo)(()=>{
        const message = "You have selected the {{Guidebook URL}} tag but don't have a guidebook integration configured. Please check out our integrations page to see the guidebook integrations we support.";
        if (typeof value === "string" && value.search("{{Guidebook URL}}") !== -1 && !(touchstay === null || touchstay === void 0 ? void 0 : touchstay.isConnected)) {
            return message;
        }
        if (typeof value === "object") {
            const hasGuidebookUrlTag = Object.values(value).some((message)=>typeof message === "string" && message.search("{{Guidebook URL}}") !== -1);
            if (hasGuidebookUrlTag && !(touchstay === null || touchstay === void 0 ? void 0 : touchstay.isConnected)) {
                return message;
            }
        }
        return undefined;
    }, [
        value,
        touchstay
    ]);
    return warningMessage;
}
